<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <circle style="fill:#FED159;" cx="115.725" cy="115.725" r="115.725" />
    <path
      style="fill:#FFE4A9;"
      d="M231.441,115.726c0,5.446-0.383,10.815-1.122,16.057c-7.065,51.052-47.468,91.455-98.533,98.546
	c-5.254,0.727-10.611,1.11-16.069,1.11c-10.815,0-21.298-1.492-31.233-4.272L162.815,9.988
	C203.256,28.021,231.441,68.577,231.441,115.726z"
    />
    <path
      style="fill:#F6C454;"
      d="M230.319,131.783c-7.065,51.052-47.468,91.455-98.533,98.546
	C138.864,179.264,179.254,138.86,230.319,131.783z"
    />
    <circle style="fill:#FED159;" cx="255.311" cy="255.311" r="115.725" />
    <path
      style="fill:#FFE4A9;"
      d="M371.028,255.313c0,5.841-0.434,11.567-1.263,17.179c-7.473,50.159-47.111,89.81-97.283,97.258
	c-5.599,0.842-11.338,1.275-17.179,1.275c-10.815,0-21.298-1.492-31.233-4.272l78.331-217.178
	C342.842,167.606,371.028,208.163,371.028,255.313z"
    />
    <path
      style="fill:#F6C454;"
      d="M369.765,272.49c-7.473,50.159-47.111,89.81-97.283,97.258
	C279.943,319.577,319.606,279.927,369.765,272.49z"
    />
    <path
      style="fill:#FED159;"
      d="M512,396.288c0,63.907-51.804,115.712-115.724,115.712c-10.815,0-21.298-1.492-31.233-4.272
	c-48.731-13.621-84.479-58.36-84.479-111.439c0-63.92,51.804-115.725,115.712-115.725c16.771,0,32.713,3.558,47.098,9.986
	C483.815,308.582,512,349.138,512,396.288z"
    />
    <path
      style="fill:#FFE4A9;"
      d="M512,396.288c0,63.907-51.804,115.712-115.724,115.712c-10.815,0-21.298-1.492-31.233-4.272
	l78.331-217.178C483.815,308.582,512,349.138,512,396.288z"
    />
    <g>
      <polygon
        style="fill:#F6C454;"
        points="415.677,352.518 384.852,352.518 384.852,387.526 405.844,387.526 405.844,406.656 
		384.852,406.656 384.852,440.046 426.951,440.046 426.951,459.176 344.998,459.176 344.998,440.046 365.722,440.046 
		365.722,406.656 344.998,406.656 344.998,387.526 365.722,387.526 365.722,333.388 434.807,333.388 434.807,374.875 
		415.677,374.875 	"
      />
      <polygon
        style="fill:#F6C454;"
        points="270.824,226.987 270.824,211.543 241.07,211.543 241.07,228.645 260.417,228.645 
		260.417,247.775 241.07,247.775 241.07,262.85 260.417,262.85 260.417,281.98 241.07,281.98 241.07,299.082 270.824,299.082 
		270.824,285.256 289.954,285.256 289.954,318.212 221.94,318.212 221.94,281.98 206.84,281.98 206.84,262.85 221.94,262.85 
		221.94,247.775 206.84,247.775 206.84,228.645 221.94,228.645 221.94,192.413 289.954,192.413 289.954,226.987 	"
      />
      <polygon
        style="fill:#F6C454;"
        points="93.934,87.26 93.934,106.161 156.643,106.161 156.643,163.322 125.282,163.322 
		125.282,185.04 106.152,185.04 106.152,163.322 74.804,163.322 74.804,144.192 137.512,144.192 137.512,125.291 74.804,125.291 
		74.804,68.129 106.152,68.129 106.152,46.398 125.282,46.398 125.282,68.129 156.643,68.129 156.643,87.26 	"
      />
    </g>
    <g>
      <polygon
        style="fill:#FED159;"
        points="156.643,68.129 156.643,87.26 134.949,87.26 141.849,68.129 	"
      />
      <polygon
        style="fill:#FED159;"
        points="156.643,106.161 156.643,163.322 125.282,163.322 125.282,185.04 106.152,185.04 
		106.152,167.084 114.416,144.192 137.512,144.192 137.512,125.291 121.226,125.291 128.126,106.161 	"
      />
      <polygon
        style="fill:#FED159;"
        points="260.417,281.98 254.64,281.98 260.417,265.974 	"
      />
      <polygon
        style="fill:#FED159;"
        points="289.954,192.413 289.954,226.987 274.484,226.987 286.944,192.413 	"
      />
      <polygon
        style="fill:#FED159;"
        points="270.824,285.256 289.954,285.256 289.954,318.212 241.58,318.212 248.48,299.082 
		270.824,299.082 	"
      />
      <polygon
        style="fill:#FED159;"
        points="405.844,406.656 401.495,406.656 405.844,394.617 	"
      />
      <polygon
        style="fill:#FED159;"
        points="389.456,440.046 426.951,440.046 426.951,459.176 382.557,459.176 	"
      />
      <polygon
        style="fill:#FED159;"
        points="434.807,333.388 434.807,374.875 415.677,374.875 415.677,367.351 427.921,333.388 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
